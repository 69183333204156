/*
 * @Author: sharon
 * @Date: 2022-01-17 13:29:55
 * @LastEditTime: 2022-01-17 13:31:41
 * @LastEditors: Please set LastEditors
 * @Description: 修复el-image预览功能的infinite
 * @FilePath: /miao-kuan/src/modules/miaokuan-manage/components/shopDetail/composables/use-fix-previewInfinite.ts
 */
export default () => {
  const handleSwitchBtn = () => {
    const imgs = document.querySelectorAll('.el-image-viewer__canvas img');
    const first = imgs[0] as HTMLElement;
    const end = imgs[imgs.length - 1] as HTMLElement;
    const preBtn = document.querySelector('.el-image-viewer__prev') as HTMLElement;
    const nextBtn = document.querySelector('.el-image-viewer__next') as HTMLElement;

    if (first?.style.display !== 'none') {
      preBtn.style.display = 'none';
    } else if (end.style.display !== 'none') {
      nextBtn.style.display = 'none';
    } else {
      preBtn.removeAttribute('style');
      nextBtn.removeAttribute('style');
    }
  };

  const handleClick = () => {
    setTimeout(() => {
      handleSwitchBtn();
      document.querySelector('.el-image-viewer__wrapper')?.addEventListener('click', (e) => {
        const targetClassList = (e.target as HTMLElement).classList;
        if (
          targetClassList.contains('el-image-viewer__btn')
          || targetClassList.contains('el-icon-arrow-left')
          || targetClassList.contains('el-icon-arrow-right')
        ) {
          handleSwitchBtn();
        }
      });
    }, 10);
  };
  return {
    handleClick,
  };
};
