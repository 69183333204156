/*
 * @Author: sharon
 * @Date: 2022-01-12 15:53:06
 * @LastEditTime: 2022-01-18 16:17:33
 * @LastEditors: Please set LastEditors
 * @Description: 商品详情页api
 * @FilePath: /miao-kuan/src/modules/miaokuan-manage/components/shopDetail/api/index.ts
 */
import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';

import * as Types from './type.d';

const server = DOMAIN_SYSTEM_ENUM.tiangong;

/**
 * 秀场详情页
 */
export function getBrandShopDetailByShowId(data: Types.IBrandFindShowPictureListReq) {
  return http.post<Types.IBrandFindShowPictureListRes>({
    url: '/moken-portal/web/v1/brand/find-show-picture-list',
    loading: true,
    server,
    data,
  });
}

/**
 * 秀场详情页
 */
export function getShowDetail(data: Types.IBrandFindShowDetailReq) {
  return http.post<Types.IBrandFindShowDetailRes>({
    url: '/moken-portal/web/v1/brand/find-show-detail',
    loading: true,
    server,
    data,
  });
}
