
import { defineComponent, PropType, ref } from 'vue';
import { numberfuns } from 'cx-utils';
import { IDetailItem } from '../type.d';
import useFixImageInfinite from '../composables/use-fix-previewInfinite';

export default defineComponent({
  emits: ['select-img', 'image'],
  props: {
    data: {
      type: Object as PropType<IDetailItem>,
      default: () => ({}),
    },
    goodsList: {
      type: Array as PropType<Array<IDetailItem>>,
      default: () => [],
    },
    pageSize: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const previewList = ref<string[]>([]);
    const upperPage = Math.floor(Number(props.data.pictureOrder) / props.pageSize);
    const nowPage = Number(props.data.pictureOrder) === props.pageSize ? upperPage - 1 : upperPage;
    previewList.value = props.goodsList
      .map(item => item.ossPictureLink)
      .slice(nowPage * props.pageSize, (nowPage + 1) * props.pageSize);
    const { handleClick } = useFixImageInfinite();

    /* eslint-disable no-restricted-globals */
    const intValidator = (num: string | number) => {
      const Nnum = Number(num);
      if (isNaN(Nnum) || Nnum < 0) return 0;
      if (Nnum < 10000) return Nnum;
      const Fnum = +numberfuns.formatFloat(Nnum / 10000, { pos: 1, round: true });
      return `${Fnum} w`;
    };

    const handleSelectImgItem = (options: any, type:string) => {
      emit('select-img', options, type);
    };

    const handleImageItem = (row: IDetailItem) => {
      emit('image', row);
    };

    return {
      handleImageItem,
      handleSelectImgItem,
      intValidator,
      previewList,
      handleClick,
    };
  },
});
