
import { defineComponent, ref, nextTick, onMounted, reactive, toRefs } from 'vue';
import { processRouterQueryParams } from '@/utils/';
import useBlogDetail from '../composables/use-detail-list';
import { cloneDeep } from 'lodash-es';
import type {
  ISearchSettingConfig,
  IPostDefaultSearchConfig,
  ISearchKeys,
  IPostDetailParams,
} from '../type.d';
import { IBrandFindShowDetailRes } from '@/modules/miaokuan-manage/common/components/shopDetail/api/type.d';
import type { IBrandSubscribeReq } from '@/modules/miaokuan-manage/sub/api/type.d';
import { postBrandSubscribe } from '@/modules/miaokuan-manage/sub/api';
import { ElMessage, ElForm } from 'element-plus';
import NumberRangeInput from '@/modules/miaokuan-manage/common/components/number-range-input';
import SelectImgDialog from '@/modules/miaokuan-manage/common/components/select-img-dialog/index.vue';
import GoodItem from './good-item.vue';
import { SUB_STATUS_ENUMS } from '@/modules/miaokuan-manage/common/constant';
import CancelSub from '@/modules/miaokuan-manage/common/components/cancel-sub/index.vue';
import { useMathFormat } from '@/modules/miaokuan-manage/common/composables/use-math-format';
import { trackingClick } from '@/utils/tracking';
import { usePictureOperation } from '../composables/use-picture-operation';
import dayjs from 'dayjs';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  components: {
    GoodItem,
    SelectImgDialog,
    NumberRangeInput,
    // Pagination,
    CancelSub,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { intValidator } = useMathFormat();
    const goodsEl = ref<null|HTMLElement>(null);
    const defaultSearchEl = ref<InstanceType<typeof ElForm> | null>(null);
    const showId = ref<string>(route.query.id as string);
    const detailData = reactive<{detail:IBrandFindShowDetailRes;}>({
      detail: {
        id: '',
        brandName: '',
        brandZhName: '',
        brandLogo: '',
        brandIntroduce: '',
        showDate: '',
        showName: '',
        latelyUpdatedTime: '',
        alreadySubscribe: false,
      },
    });

    // 列表查询
    const {
      getDetail,
      handleCurrentSizeChange,
      defaultSearchKeys,
      baseParams,
      params,
      goodsList,
      reload,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      sortList,
      sortConfig,
      handleChangeSort,
      initPagination,
      currentViewPage,
      pageSize,
      total,
      channelId,
    } = useBlogDetail();

    // 默认搜索条件
    const defaultSearchConfig = ref<IPostDefaultSearchConfig<IPostDetailParams>>({
      params: cloneDeep(baseParams),
    });

    // 缓存到本地的需展示搜索项key[]
    const localSaveSettingKeys = (keys: string[]) => {
      localStorage.setItem('style_search_keys', JSON.stringify(keys));
    };

    // 面包屑跳转
    const handleRouteToList = () => {
      router.push({
        name: 'BrandDetail',
        query: {
          brandName: route.query?.brandName,
        },
      });
    };

    // 初始化页面需要展示的搜索项
    const initSettingConfig = () => {
      const keys: Array<ISearchKeys> = [
        // SEARCH_BASE_SETTING_ENUM.CATEGORY, // 品类
      ];
      defaultSearchKeys.value = keys;
      localSaveSettingKeys(keys);
    };
    initSettingConfig();

    // 搜索项配置
    const searchSettingConfig = ref<ISearchSettingConfig>({
      visible: false,
    });
    const handleChangeSettingConfigShow = (visible: boolean) => {
      searchSettingConfig.value.visible = visible;
    };
    const handleConfirmSearchSetting = (keys: Array<ISearchKeys>) => {
      defaultSearchKeys.value = [...keys];
      localSaveSettingKeys(keys);
    };

    const {
      handleSelectImgItem,
      selectImgConfig,
      designTaskOptions,
      handleSelectSuccess,
      handleDownloadByChannel,
    } = usePictureOperation();

    const status = ref<SUB_STATUS_ENUMS>(SUB_STATUS_ENUMS.SUB);

    // 订阅
    const handleSub = async () => {
      trackingClick({
        eventName: '订阅',
        targetId: detailData.detail.id,
      });
      const param:IBrandSubscribeReq = {
        brandId: detailData.detail.id as string,
        isSubscribe: SUB_STATUS_ENUMS.SUB,
      };
      await postBrandSubscribe(param);
      ElMessage.success('订阅成功');
      status.value = SUB_STATUS_ENUMS.SUB;
    };
    // 取消订阅
    const cancelSubConfig = ref<{data:any;visible:boolean;}>(
      { visible: false, data: [] },
    );
    const handleSubCancel = async () => {
      trackingClick({
        eventName: '取消订阅弹窗-确定',
        targetId: detailData.detail.id,
      });
      const param:IBrandSubscribeReq = {
        brandId: detailData.detail.id as string,
        isSubscribe: SUB_STATUS_ENUMS.CANCEL,
      };
      await postBrandSubscribe(param);
      ElMessage.success('取消订阅成功');
      cancelSubConfig.value.visible = false;
      status.value = SUB_STATUS_ENUMS.CANCEL;
    };
    const openCancelSub = () => {
      trackingClick({
        eventName: '取消订阅',
        targetId: detailData.detail.id,
      });
      cancelSubConfig.value.visible = true;
    };

    const init = async () => {
      await nextTick();
      // url 获取请求参数
      const urlParams = processRouterQueryParams(params.value);
      urlParams.pageNum = 0;
      params.value = urlParams;
      detailData.detail = (await getDetail());
      status.value = SUB_STATUS_ENUMS[detailData.detail.alreadySubscribe ? 'SUB' : 'CANCEL'];
      initPagination(goodsEl.value!);
      reload();
    };

    onMounted(() => {
      init();
    });

    return {
      ...toRefs(detailData),
      handleCurrentSizeChange,
      total,
      currentViewPage,
      pageSize,
      goodsEl,
      defaultSearchEl,
      defaultSearchKeys,
      defaultSearchConfig,
      handleChangeSort,
      sortList,
      sortConfig,
      handleChangeSettingConfigShow,
      handleConfirmSearchSetting,
      searchSettingConfig,
      designTaskOptions,
      handleSelectImgItem,
      selectImgConfig,
      handleDownloadByChannel,
      goodsList,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      params,
      status,
      handleSub,
      handleSubCancel,
      cancelSubConfig,
      openCancelSub,
      intValidator,
      handleSelectSuccess,
      dayjs,
      showId,
      channelId,
      handleRouteToList,
    };
  },
  render() {
    return (
      <div>
        <div class="breadcrumb">
          <span>妙款 / </span>
          <span style="cursor: pointer;" onClick={this.handleRouteToList}>品牌 /</span>
          <span class="breadcrumb_active"> 商品详情</span>
        </div>
        <main class="container">
          <div class="conditions-container">
            <section class="header-info flex flex-justify-between">
              <div class='left'>
                <div class="left-basic">
                  <strong>{this.detail.brandName}{this.detail.brandZhName}{this.detail.showName}</strong>
                  <div class='left-item'>
                    <div class='left-item__infoItem'>
                      <span class='margin-right-5'>发布会时间</span>
                      <span>
                      { this.detail.showDate
                        ? dayjs(new Date(this.detail.showDate))
                          .format('YYYY年MM月DD日') : ''}
                      </span>
                    </div>
                    <div class='left-item__infoItem'>
                      <span class='margin-right-5'>更新时间</span>
                      <span>
                      {this.detail.latelyUpdatedTime
                        ? dayjs(new Date(this.detail.latelyUpdatedTime)).format('YYYY年MM月DD日')
                        : ''}
                      </span>
                    </div>
                  </div>
              </div>
              </div>
              <div class='right'>
                <div>
                  <el-button
                    type={this.status === SUB_STATUS_ENUMS.CANCEL ? 'primary' : 'default'}
                    icon={this.status === SUB_STATUS_ENUMS.CANCEL ? 'el-icon-plus' : ''}
                    onClick={this.status === SUB_STATUS_ENUMS.CANCEL ? this.handleSub : this.openCancelSub}
                  >{this.status === SUB_STATUS_ENUMS.CANCEL ? '订阅' : '取消订阅'} </el-button>
                  {
                    // 品牌v1.0隐藏下载按钮
                    null
                  //     : (<el-button
                  //   onClick={() => {
                  //     this.handleDownloadByChannel(
                  //       this.channelId,
                  //       this.showId,
                  //     );
                  //   }}
                  // >下载图片</el-button>)
                  }
                </div>
              </div>
            </section>
          </div>
          <section>
            <div
              class="goods-container"
              ref="goodsEl"
              v-infinite-scroll={this.loadMore}
              infinite-scroll-distance={50}
              infinite-scroll-disabled={this.listDisabled}
              infinite-scroll-immediate={false}
            >
              {this.goodsList.map((v, i) => (
                <good-item
                  key={i}
                  data={v}
                  goodsList={this.goodsList}
                  pageSize={Number(this.pageSize)}
                  onSelectImg={this.handleSelectImgItem}
                />
              ))}
            </div>
            {this.isEmpty ? (
              <empty />
            ) : (
              <div class="load-other">
                {this.listLoading && (
                  <p><i class="el-icon-loading"></i> 加载中...</p>
                )}
                {this.finish && (
                  <p>已经到底了</p>
                )}
              </div>
            )}
            <list-pagination
              total={this.total}
              current={this.currentViewPage}
              pageSize={this.pageSize}
              onCurrentChange={this.handleCurrentSizeChange}
            />
          </section>
        </main>
        <select-img-dialog
          v-model={[this.selectImgConfig.visible, 'visible']}
          data={this.selectImgConfig.data}
          options={this.designTaskOptions}
          onSuccess={this.handleSelectSuccess}
        />
        <cancel-sub v-model={[this.cancelSubConfig.visible, 'visible']} onSubCancel={this.handleSubCancel}/>
      </div>
    );
  },
});
